import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
var __jsx = React.createElement;
function MarkerEthos(props) {
  return __jsx("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    width: "29",
    height: "43",
    viewBox: "0 0 29 43",
    fill: "none"
  }, props), __jsx("path", {
    fill: "#2A9E41",
    stroke: "#fff",
    strokeWidth: "2",
    d: "M14.5 1c4.813 0 8.235 1.99 10.425 4.915C27.087 8.802 28 12.53 28 16c0 1.468-.522 3.383-1.28 5.427-.768 2.074-1.823 4.392-2.974 6.709-2.304 4.635-5.032 9.33-6.735 12.172a2.915 2.915 0 0 1-5.022 0c-1.703-2.842-4.43-7.537-6.735-12.172-1.151-2.317-2.206-4.635-2.975-6.71C1.522 19.384 1 17.469 1 16c0-3.47.913-7.198 3.074-10.085C6.264 2.99 9.687 1 14.5 1Z"
  }), __jsx("path", {
    fill: "#fff",
    d: "m10.796 18.611 2.352-1.422V20h3.538v-2.804l2.349 1.422 1.795-3.151-2.413-1.463 2.435-1.46-1.796-3.155-2.37 1.422V8h-3.541v2.804l-2.35-1.423L9 12.533l2.414 1.482L9 15.455l1.796 3.156Zm3.232-1.481c0 .102-.08.185-.18.185-.099 0-.18-.083-.18-.185 0-.337.244-.62.568-.66l.022.37a.298.298 0 0 0-.23.29Zm2.42-4.16a.272.272 0 0 1 .234-.304.268.268 0 0 1 .295.24.283.283 0 0 1 0 .064c0 .77-.719 1.111-1.257 1.185l.028-.559c.28-.07.683-.237.683-.626h.018Zm-2.355.675.028.544a.4.4 0 0 0-.418.383.404.404 0 0 0 .372.432h.442l-.094-1.745a1.006 1.006 0 0 1-.325-1.357.943.943 0 0 1 1.316-.336 1.007 1.007 0 0 1 0 1.693l-.165 3.06h.46a.3.3 0 0 0 .294-.3.3.3 0 0 0-.294-.304v-.37a.653.653 0 0 1 .693.615.66.66 0 0 1-.693.714h-.496l-.05.956h-.52l-.112-2.085h-.474c-.51-.006-.92-.436-.914-.962.005-.525.422-.947.932-.942l.018.004Z"
  }));
}
export default MarkerEthos;