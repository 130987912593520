import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _defineProperty from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InfoWindow, useMap } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { LocationMarker } from './LocationMarker';
import Heading from '../../atoms/Heading';
import Anchor from '../../atoms/Anchor';
import EyesHappyPets from '../../../static/images/icons/EyesHappyPets';
import CornerUpRight from '../../../static/images/icons/CornerUpRight';
import { convertPhoneFormat } from '../../../utils/utilityFunction';

/**
 * The ClusteredLocationMarkers component is responsible for integrating the
 * markers with the markerclusterer.
 */
export var ClusteredLocationMarkers = function ClusteredLocationMarkers(_ref) {
  var children = _ref.children,
    locations = _ref.locations,
    isLoadingLocations = _ref.isLoadingLocations,
    handleSelectLocation = _ref.handleSelectLocation,
    selectedLocationKey = _ref.selectedLocationKey,
    setSelectedLocationKey = _ref.setSelectedLocationKey;
  var _useState = useState({}),
    markers = _useState[0],
    setMarkers = _useState[1];

  // create the markerClusterer once the map is available and update it when
  // the markers are changed
  var map = useMap();
  var clusterer = useMemo(function () {
    if (!map) return null;
    return new MarkerClusterer({
      map: map
    });
  }, [map]);
  useEffect(function () {
    if (!clusterer) return;
    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  // this callback will effectively get passsed as ref to the markers to keep
  // tracks of markers currently on the map
  var setMarkerRef = useCallback(function (marker, key) {
    setMarkers(function (markers) {
      if (marker && markers[key] || !marker && !markers[key]) return markers;
      if (marker) {
        return _objectSpread(_objectSpread({}, markers), {}, _defineProperty({}, key, marker));
      } else {
        var _ = markers[key],
          newMarkers = _objectWithoutProperties(markers, [key].map(_toPropertyKey));
        return newMarkers;
      }
    });
  }, []);
  var handleInfoWindowClose = useCallback(function () {
    setSelectedLocationKey(null);
    handleSelectLocation(null);
  }, []);
  var handleMarkerClick = function handleMarkerClick(tree) {
    setSelectedLocationKey(tree.yId);
    handleSelectLocation(tree);
    map.setCenter({
      lat: tree.lat,
      lng: tree.lng
    });
    map.setZoom(15);
  };
  return __jsx(React.Fragment, null, locations.map(function (location) {
    return __jsx(LocationMarker, {
      key: location.yId,
      location: location,
      onClick: handleMarkerClick,
      setMarkerRef: setMarkerRef,
      hasOldIcons: true
    });
  }), selectedLocationKey && __jsx(InfoWindow, {
    anchor: markers[selectedLocationKey],
    onCloseClick: handleInfoWindowClose
  }, isLoadingLocations ? __jsx("span", {
    className: "nva-map__iw-loading u-loading u-block"
  }) : children));
};
ClusteredLocationMarkers.OldInfoWindow = function OldInfoWindow(_ref2) {
  var _selectedLocation$web;
  var selectedLocation = _ref2.selectedLocation;
  if (!selectedLocation) {
    return;
  }
  return __jsx("div", {
    className: "nva-maps-hhpets__info-window infoWindow"
  }, __jsx(Heading, {
    HeadingType: "h3",
    className: "nva-maps-hhpets__selected-place caption"
  }, selectedLocation.name), selectedLocation.mainPhone && __jsx("div", {
    className: "nva-maps-hhpets__selected-place-phone phone-class"
  }, __jsx("span", null, "Phone: "), __jsx(Anchor, {
    title: "Phone: ".concat(convertPhoneFormat(selectedLocation.mainPhone)),
    onClick: function onClick(e) {
      return e.stopPropagation();
    },
    href: "tel:".concat(convertPhoneFormat(selectedLocation.mainPhone)),
    to: "tel:".concat(convertPhoneFormat(selectedLocation.mainPhone)),
    className: "external-links"
  }, __jsx("span", null, convertPhoneFormat(selectedLocation.mainPhone)))), (selectedLocation === null || selectedLocation === void 0 ? void 0 : (_selectedLocation$web = selectedLocation.websiteUrl) === null || _selectedLocation$web === void 0 ? void 0 : _selectedLocation$web.url) && __jsx(Anchor, {
    title: "Visit Site",
    "aria-label": 'Visit Site ' + selectedLocation.name,
    target: "_blank",
    rel: "noopener noreferrer",
    onClick: function onClick(e) {
      return e.stopPropagation();
    },
    to: selectedLocation.websiteUrl.url,
    className: "nva-maps-hhpets__selected-place-website link-visit-websit"
  }, __jsx("span", {
    className: "icon"
  }, __jsx(CornerUpRight, {
    className: "corner-up-right"
  })), "Visit Website"), selectedLocation && __jsx(Anchor, {
    title: "View in List",
    target: "_blank",
    rel: "noopener noreferrer",
    onClick: function onClick() {
      setToggleMap(!toggleMap);
    },
    className: "nva-maps-hhpets__selected-place-view-in-list link-view-on-list"
  }, __jsx("span", {
    className: "icon"
  }, __jsx(EyesHappyPets, {
    className: "eyes-toggle-map"
  })), "View in List"));
};