import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
var __jsx = React.createElement;
function DeleteClose(props) {
  return __jsx("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 28 28",
    width: "28",
    height: "28",
    fill: "none",
    "aria-hidden": "true"
  }, props), __jsx("path", {
    fill: "currentColor",
    d: "M10.209 19.862 14 16.072l3.791 3.79a1.007 1.007 0 0 0 1.417 0 1.007 1.007 0 0 0 0-1.417l-3.791-3.79 3.791-3.792a1.007 1.007 0 0 0 0-1.417 1.007 1.007 0 0 0-1.417 0L14 13.237l-3.791-3.79a1.007 1.007 0 0 0-1.417 0 1.007 1.007 0 0 0 0 1.416l3.791 3.791-3.791 3.791a1.007 1.007 0 0 0 0 1.417 1.007 1.007 0 0 0 1.417 0Z"
  }));
}
export default DeleteClose;