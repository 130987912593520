import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function EyesHappyPets(props) {
  return __jsx("svg", _extends({}, props, {
    width: "15",
    height: "11",
    viewBox: "0 0 15 11",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }), __jsx("path", {
    d: "M1.0835 5.49992C1.0835 5.49992 3.41683 0.833252 7.50016 0.833252C11.5835 0.833252 13.9168 5.49992 13.9168 5.49992C13.9168 5.49992 11.5835 10.1666 7.50016 10.1666C3.41683 10.1666 1.0835 5.49992 1.0835 5.49992Z",
    stroke: "white",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M7.50016 7.24992C8.46666 7.24992 9.25016 6.46642 9.25016 5.49992C9.25016 4.53342 8.46666 3.74992 7.50016 3.74992C6.53366 3.74992 5.75016 4.53342 5.75016 5.49992C5.75016 6.46642 6.53366 7.24992 7.50016 7.24992Z",
    stroke: "white",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}
export default EyesHappyPets;