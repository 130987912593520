import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function CornerUpRight(props) {
  return __jsx("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24"
  }, props), __jsx("polyline", {
    points: "15 14 20 9 15 4"
  }), __jsx("path", {
    d: "M4 20v-7a4 4 0 0 1 4-4h12"
  }));
}
export default CornerUpRight;