import _defineProperty from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
/*
 * Side-bar Component
 * different from Infowindow on map because of possible change requests to the side list
 */
import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import styles from './ListComponent.style';
import Para from '../../atoms/Para';
import Anchor from '../../atoms/Anchor';
import { FaCar } from 'react-icons/fa';
import { convertPhoneFormat } from '../../../utils/utilityFunction';
import Heading from '../../atoms/Heading';
import Address from '../../atoms/Address';
import CalendarHappy from '../../../static/images/icons/CalendarHappy';
import EquineHappy from '../../../static/images/icons/EquineHappy';
import GeneralPracticeHappy from '../../../static/images/icons/GeneralPracticeHappy';
import PetResortHappy from '../../../static/images/icons/PetResortHappy';
import SpecialtyHappy from '../../../static/images/icons/SpecialtyHappy';
import ViewOnMap from '../../../static/images/icons/ViewOnMap';
import CornerUpRight from '../../../static/images/icons/CornerUpRight';
import PhoneHappyPets from '../../../static/images/icons/PhoneHappyPets';
var ListComponentBlock = styled.section.withConfig({
  componentId: "sc-ffn6j5-0"
})(["", ";"], styles);
var ListComponent = function ListComponent(props) {
  var _selectedClinic$addre, _selectedClinic$addre2;
  var name = props.name,
    address = props.address,
    fax = props.fax,
    phoneNumber = props.phoneNumber,
    sms = props.sms,
    reservationLink = props.reservationLink,
    setSelectedClinic = props.setSelectedClinic,
    selectedClinic = props.selectedClinic,
    item = props.item,
    website = props.website,
    setData = props.setData,
    data = props.data,
    distance = props.distance,
    duration = props.duration,
    collapseButtonDisplay = props.collapseButtonDisplay,
    listOnClick = props.listOnClick,
    setToggle = props.setToggle,
    toggleMap = props.toggleMap,
    setTopOfSelectedCardToParent = props.setTopOfSelectedCardToParent,
    itemIndex = props.itemIndex,
    businessType = props.businessType;

  /*
   * Method passed from child to parent component
   * to handle the map interaction when clicked from the sidebar
   * so that map zooms in when you click on a side list
   */

  // eslint-disable-next-line no-unused-vars
  var cardEl = useRef(null);
  var _useState = useState(false),
    dataExpanded = _useState[0],
    setDataExpanded = _useState[1];
  var businessTypeIcon;
  var businessTypeLabel;
  switch (businessType) {
    case 'GENERAL_PRACTICE':
      businessTypeIcon = __jsx(GeneralPracticeHappy, null);
      businessTypeLabel = 'Neighborhood Vet';
      break;
    case 'PET_RESORTS':
      businessTypeIcon = __jsx(PetResortHappy, null);
      businessTypeLabel = 'Pet Resort';
      break;
    case 'EQUINE':
      businessTypeIcon = __jsx(EquineHappy, null);
      businessTypeLabel = 'Equine';
      break;
    case 'SPECIALTY_+_ER':
      businessTypeIcon = __jsx(SpecialtyHappy, null);
      businessTypeLabel = 'Specialty / Emergency Hospital';
      break;
    default:
      break;
  }
  useLayoutEffect(function () {
    if (cardEl.current && selectedClinic && selectedClinic.index === itemIndex) {
      var cardTop = cardEl.current.getBoundingClientRect();
      if (cardTop.height) {
        setTopOfSelectedCardToParent(cardTop.top);
      }
    }
    return function () {
      // cleanup
      setTopOfSelectedCardToParent(null);
    };
  }, [selectedClinic, cardEl, toggleMap]);
  return __jsx("div", {
    key: itemIndex,
    ref: cardEl
  }, __jsx(ListComponentBlock, {
    className: "nva-maps-hhpets__list-component"
  }, collapseButtonDisplay && __jsx("button", {
    className: 'collapse-button' + (dataExpanded ? ' minus' : ' plus'),
    onClick: function onClick() {
      return setDataExpanded(!dataExpanded);
    }
  }), __jsx("div", {
    onClick: listOnClick || function (event) {
      if (selectedClinic && selectedClinic.yextRoutableCoordinate && selectedClinic.yextRoutableCoordinate.latitude === item.yextRoutableCoordinate.latitude) {
        return;
      }
      setData && setData(_objectSpread(_objectSpread({}, data), {}, {
        zoomLevel: 12
      }));
      setSelectedClinic(_objectSpread({}, item));
    },
    className: "list-item ".concat(selectedClinic && ((_selectedClinic$addre = selectedClinic.address) === null || _selectedClinic$addre === void 0 ? void 0 : _selectedClinic$addre.line1) === address.line1 && ((_selectedClinic$addre2 = selectedClinic.address) === null || _selectedClinic$addre2 === void 0 ? void 0 : _selectedClinic$addre2.postalCode) === address.postalCode ? 'selected' : '')
  }, website ? __jsx(Heading, {
    title: name,
    HeadingType: "h3",
    className: "nva-maps-hhpets__list-component-location-title location-title"
  }, name) : __jsx(Heading, {
    HeadingType: "h3",
    className: "nva-maps-hhpets__list-component-location-title location-title"
  }, name), __jsx("div", {
    className: "nva-maps-hhpets__list-component-location-address div-list-address"
  }, __jsx("span", {
    className: "icon"
  }, __jsx(CornerUpRight, {
    className: "corner-up-right"
  })), __jsx(Address, {
    className: "list-item-text",
    addressLine1: address.line1,
    addressLine2: address.line2,
    city: address.city,
    state: address.region,
    postalCode: address.postalCode
  })), __jsx("div", {
    className: 'nva-maps-hhpets__list-component-cta cta-section list-component-content' + (collapseButtonDisplay ? ' collapse-component' : '') + (dataExpanded ? ' show' : ' hide')
  }, __jsx("div", {
    className: "div-list-contacts"
  }, __jsx("span", {
    className: "icon"
  }, __jsx(PhoneHappyPets, {
    className: "nva-maps-hhpets__list-component-phone-pets phone-pets"
  })), __jsx("div", null, phoneNumber && __jsx("div", {
    className: "nva-maps-hhpets__list-item-inline list-item-inline"
  }, __jsx("span", null, "Phone: "), __jsx(Anchor, {
    title: "Phone: ".concat(convertPhoneFormat(phoneNumber)),
    onClick: function onClick(e) {
      return e.stopPropagation();
    },
    href: "tel:".concat(convertPhoneFormat(phoneNumber)),
    to: "tel:".concat(convertPhoneFormat(phoneNumber)),
    className: "external-links",
    tabIndex: "0"
  }, __jsx("span", null, convertPhoneFormat(phoneNumber)))), sms && __jsx("div", {
    className: "nva-maps-hhpets__list-item-inline list-item-inline"
  }, __jsx("span", null, "Text: "), __jsx(Anchor, {
    title: "Text: ".concat(convertPhoneFormat(sms)),
    onClick: function onClick(e) {
      return e.stopPropagation();
    },
    href: "sms:".concat(convertPhoneFormat(sms)),
    to: "sms:".concat(convertPhoneFormat(sms)),
    className: "external-links",
    tabIndex: "0"
  }, __jsx("span", null, convertPhoneFormat(sms)))), fax && __jsx("div", {
    className: "nva-maps-hhpets__list-item-inline list-item-inline"
  }, __jsx("span", null, "Fax: "), __jsx(Anchor, {
    title: "Fax: ".concat(convertPhoneFormat(fax)),
    onClick: function onClick(e) {
      return e.stopPropagation();
    },
    href: "tel:".concat(convertPhoneFormat(fax)),
    to: "tel:".concat(convertPhoneFormat(fax)),
    className: "external-links",
    tabIndex: "0"
  }, __jsx("span", null, convertPhoneFormat(fax)))))), reservationLink ? __jsx("div", {
    className: "make-reservation"
  }, __jsx(Anchor, {
    title: "Make Reservation",
    "aria-label": "Make a Reservation for " + name,
    onClick: function onClick(e) {
      return e.stopPropagation();
    },
    styleType: "primary-anchor",
    className: "nav-btn icon-button cta-button make-reservation-button",
    to: reservationLink,
    target: "_blank",
    rel: "noopener noreferrer",
    tabIndex: "0"
  }, __jsx("span", {
    className: "icon"
  }, __jsx(CalendarHappy, {
    className: "calendar-icon-happy"
  })), __jsx("span", {
    className: "class-span"
  }, "MAKE RESERVATION"))) : null), __jsx("div", {
    className: "footer-content"
  }, __jsx("div", {
    className: "view-page-view-map-container"
  }, __jsx("div", null, website ? __jsx(Anchor, {
    title: "Visit Site",
    "aria-label": "Visit Site " + name,
    onClick: function onClick(e) {
      return e.stopPropagation();
    },
    to: website,
    styleType: "primary-anchor",
    className: "nav-btn icon-button cta-button view-site-button",
    target: "_blank",
    rel: "noopener noreferrer",
    tabIndex: "0"
  }, __jsx("span", null, "VIEW PAGE")) : null), __jsx("div", null, __jsx(Anchor, {
    tabIndex: "0",
    onClick: function onClick() {
      setToggle();
    },
    className: "view-on-map",
    target: "_blank",
    rel: "noopener noreferrer"
  }, __jsx("span", {
    className: "view-on-map-icon"
  }, __jsx(ViewOnMap, {
    className: "view-on-map-icon"
  })), __jsx("span", null, "VIEW ON MAP")))), businessTypeIcon && __jsx("div", {
    className: "business-type-icon-container"
  }, __jsx("div", {
    className: "business-type-icon-circle",
    "aria-label": businessTypeLabel,
    title: businessTypeLabel
  }, businessTypeIcon))), selectedClinic && selectedClinic.distance && selectedClinic.duration ? __jsx(Para, {
    className: "nva-maps-hhpets__list-item-inline list-item-inline"
  }, __jsx("span", {
    className: "icon"
  }, __jsx(FaCar, {
    size: 15,
    className: "fa-icon-car"
  })), __jsx("span", null, selectedClinic && selectedClinic.yextDisplayCoordinate && selectedClinic.yextDisplayCoordinate.latitude === item.yextDisplayCoordinate.latitude ? selectedClinic.distance : distance, ' ', "away,", ' ', selectedClinic && selectedClinic.yextDisplayCoordinate && selectedClinic.yextDisplayCoordinate.latitude === item.yextDisplayCoordinate.latitude ? selectedClinic.duration : duration)) : distance && duration ? __jsx(Para, {
    className: "nva-maps-hhpets__list-item-inline list-item-inline"
  }, __jsx("span", {
    className: "icon"
  }, __jsx(FaCar, {
    size: 15,
    className: "fa-icon-car"
  })), __jsx("span", null, distance, " away, ", duration)) : '')));
};
export default ListComponent;