import _defineProperty from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _businessTypeMap;
export var MAP_ID = 'bf51a910020fa25a';
export var DEFAULT_MAP_SETTINGS = {
  CENTER: {
    lat: 38.5,
    lng: -98.0
  },
  ZOOM: 4
};
export var BUSINESS_TYPE = {
  ALL: 'ALL',
  GENERAL_PRACTICE: 'GENERAL_PRACTICE',
  PET_RESORTS: 'PET_RESORTS',
  ETHOS: 'ETHOS',
  EQUINE: 'EQUINE'
};
export var filterLocationsMap = {
  'General Practice': BUSINESS_TYPE.GENERAL_PRACTICE,
  'Pet Resorts': BUSINESS_TYPE.PET_RESORTS,
  Ethos: BUSINESS_TYPE.ETHOS,
  // Speciality + ER / Compassion First
  Equine: BUSINESS_TYPE.EQUINE
};
export var businessTypeMap = (_businessTypeMap = {}, _defineProperty(_businessTypeMap, BUSINESS_TYPE.ALL, 'All Services'), _defineProperty(_businessTypeMap, BUSINESS_TYPE.GENERAL_PRACTICE, 'Neighborhood Vet'), _defineProperty(_businessTypeMap, BUSINESS_TYPE.PET_RESORTS, 'Pet Resort'), _defineProperty(_businessTypeMap, BUSINESS_TYPE.ETHOS, 'Specialty / Emergency Hospital'), _defineProperty(_businessTypeMap, BUSINESS_TYPE.EQUINE, 'Equine'), _businessTypeMap);

// ORDER IS IMPORTANT. DO NOT CHANGE. This is due to getNameFileByBusinessType() used in MapStateProvider.js
export var BUSINESS_TYPE_FOLDER = {
  GENERAL_PRACTICE: {
    name: "General Practice",
    folderId: process.env.NEXT_PUBLIC_YEXT_FOLDER_GENERAL_PRACTICE
  },
  ETHOS: {
    name: "Ethos",
    folderId: process.env.NEXT_PUBLIC_YEXT_FOLDER_ETHOS
  },
  PET_RESORTS: {
    name: "Pet Resorts",
    folderId: process.env.NEXT_PUBLIC_YEXT_FOLDER_PET_RESORTS
  },
  EQUINE: {
    name: "Equine",
    folderId: process.env.NEXT_PUBLIC_YEXT_FOLDER_EQUINE
  }
};
export var MAP_ERRORS = {
  USE_MY_LOCATION: 'Please allow the location permissions for your browser',
  FIND_UNKNOWN_LOCATION: 'Sorry, we don’t recognize that location.'
};
export var RADIUS = '25';